<template>
  <div>
    <!--begin::Notice-->
    <div
      class="alert alert-custom alert-white alert-shadow gutter-b"
      role="alert"
    >
      <div class="alert-icon alert-icon-top">
        <span class="svg-icon svg-icon-3x svg-icon-primary mt-4">
          <!--begin::Svg Icon-->
          <inline-svg src="media/svg/icons/Tools/Tools.svg" />
          <!--end::Svg Icon-->
        </span>
      </div>
      <div class="alert-text">
        <p>
          <span
            class="label label-inline label-pill label-info label-rounded mr-2"
          >
            Инструкция:
          </span>
          Выберите тест, на основе которого хотите получить журнал. Сформировать
          журнал можно как по всем, так и по конкретному сотруднику. В качестве
          времени необходимо указать месяц и год.
        </p>
      </div>
    </div>
    <!--end::Notice-->

    <div class="card">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Новый журнал
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Результат
            </a>
          </li>
        </ul>
      </div>

      <!--begin::Form-->
      <form class="form" v-on:submit="submit($event)">
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label"> Тест: </label>
                <div class="col-lg-9 col-xl-4">
                  <select class="form-control" v-model="selectedProcess">
                    <option
                      v-for="process in processes"
                      :key="process.id"
                      :value="process"
                    >
                      {{ process.processName }}
                    </option>
                  </select>
                  <div
                    class="form-text text-muted"
                    v-if="selectedProcess === null"
                  >
                    Выберите тест из списка.
                  </div>
                  <span class="form-text text-primary" v-else>{{
                    selectedProcess.description
                  }}</span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label"> Сотрудник: </label>
                <div class="col-lg-9 col-xl-4">
                  <select class="form-control" v-model="selectedEmployee">
                    <option :value="0">Все</option>
                    <option
                      v-for="employee in staff"
                      :key="employee.id"
                      :value="employee.id"
                    >
                      {{ employee.last_name }} {{ employee.first_name }}
                    </option>
                  </select>
                  <div class="form-text text-muted">
                    Укажите сотрудника для формирования журнала по нему.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label">
                  Отчетный период:
                </label>
                <div class="col-lg-4" v-if="selectedYear !== 0">
                  <select class="form-control" v-model="selectedMonth">
                    <option :value="0">Не учитывать</option>
                    <option
                      v-for="month in months"
                      :key="month.digit"
                      :value="month.digit"
                    >
                      {{ month.name }}
                    </option>
                  </select>
                  <div class="form-text text-muted">Выберите месяц.</div>
                </div>
                <div class="col-lg-4">
                  <select class="form-control" v-model="selectedYear">
                    <option :value="0">За все время</option>
                    <option v-for="year in years" :key="year" :value="year">
                      {{ year }}
                    </option>
                  </select>
                  <div class="form-text text-muted">Выберите год.</div>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <p>{{ journalData }}</p>
              <!--<div class="form-group row">
                <label class="col-lg-3 col-form-label"> Тест: </label>
                <div class="col-lg-9 col-xl-4">
                  <select class="form-control" v-model="config.loader.type">
                    <option value="false">Disabled</option>
                    <option value="spinner-logo" selected="selected">
                      Spinner &amp; Logo
                    </option>
                  </select>
                  <div class="form-text text-muted">
                    Выберите тест из списка.
                  </div>
                </div>
              </div>-->
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-9">
              <button type="submit" class="btn btn-primary font-weight-bold">
                <i class="la la-eye"></i>
                Сформировать
              </button>
              &nbsp;
              <!--<button
                v-on:click="reset($event)"
                class="btn btn-clean font-weight-bold"
              >
                <i class="la la-recycle"></i>
                Reset
              </button>-->
            </div>
          </div>
        </div>
      </form>
      <!--end::Form-->
    </div>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "journals",
  components: {},
  data() {
    var curr_year = new Date().getFullYear();
    var years_back = 5;
    return {
      tabIndex: 0,
      processes: [],
      staff: [],
      months: [
        { digit: 1, name: "Январь" },
        { digit: 2, name: "Февраль" },
        { digit: 3, name: "Март" },
        { digit: 4, name: "Апрель" },
        { digit: 5, name: "Май" },
        { digit: 6, name: "Июнь" },
        { digit: 7, name: "Июль" },
        { digit: 8, name: "Август" },
        { digit: 9, name: "Сентябрь" },
        { digit: 10, name: "Октябрь" },
        { digit: 11, name: "Ноябрь" },
        { digit: 12, name: "Декабрь" }
      ],
      years: [...Array(years_back).keys()].map(i => curr_year - i),
      selectedMonth: null,
      selectedYear: null,
      selectedProcess: null,
      selectedEmployee: 0,
      journalData: ""
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },

  created() {
    this.loadStaff();
    this.loadProcesses();
  },

  mounted() {
    this.selectedMonth = this.months.find(
      el => el.digit === new Date().getMonth() + 1
    ).digit;
    this.selectedYear = this.years[0];

    // set the tab from previous
    this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Журналы" }]);

    this.$nextTick(() => {
      const hljs = this.$el.querySelectorAll(".hljs");
      hljs.forEach(hl => {
        hl.classList.remove("hljs");
        hl.classList.add(`language-${hl.classList[1]}`);
      });
    });
  },

  destroyed() {
    localStorage.removeItem("builderTab");
  },

  methods: {
    /**
     * Set previous tab active
     */
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
      const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      this.$refs["builder-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");

      // keep active tab
      localStorage.setItem("builderTab", this.tabIndex);
    },

    /**
     * Submit form
     * @param event
     */
    submit(event) {
      event.preventDefault();
      this.loadJournal();
    },

    loadJournal() {
      var journalQuery = {
        id: this.selectedProcess.id,
        year: this.selectedYear,
        month: this.selectedMonth,
        user: this.selectedEmployee
      };
      if (this.selectedMonth === 0) delete journalQuery.month;
      if (this.selectedEmployee === 0) delete journalQuery.user;
      if (this.selectedYear === 0) {
        delete journalQuery.month;
        delete journalQuery.year;
      }
      return new Promise(resolve => {
        ApiService.getFile("api/v1/pdf2/", journalQuery)
          .then(response => {
            //    console.log(response);
            let blob = new Blob([response.data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "journal.pdf";
            link.click();
            link.remove();
            resolve(response);
          })
          .catch(({ response }) => {
            console.error(response);
          });
      });
    },

    loadStaff() {
      return new Promise(resolve => {
        ApiService.get("api/v1/users").then(({ data }) => {
          this.staff = data.results;
          resolve(data);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    loadProcesses() {
      return new Promise(resolve => {
        ApiService.get("api/v1/process").then(({ data }) => {
          this.processes = data.results;
          resolve(data);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    }
  }
};
</script>
